const dashboardsFinanceiros = [
    {
        path: '/dashboard/financeiro/fluxo-realizado-cenarios',
        name: 'ds.financeiro.fluxo.realizado.cenarios',
        meta: { title: 'Dashboard | Geral' },
        component: () => import('@/views/dashboards/financeiro/DashFluxoRealizadoCenarios.vue'),
    },
]

export default dashboardsFinanceiros
