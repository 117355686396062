import Vue from 'vue'

Vue.filter('percentual', function (value) {
    return new Intl.NumberFormat('pt-BR',
        {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value) + "%"
})

Vue.filter('numero', function (value) {
    return new Intl.NumberFormat('pt-BR',
        {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value)
})
