const dashboardsVendas = [
    {
        path: '/dashboard/vendas/diario',
        name: 'ds.vendas.diario',
        meta: { title: 'Vendas Diário' },
        component: () => import('@/views/dashboards/vendas/DashVendasDiario.vue'),
    },
    {
        path: '/dashboardsVendas/suporte',
        name: 'dashboard.suporte',
        meta: { title: 'Dashboard | Suporte' },
        component: () => import('@/views/dashboards/DashboardSuporte.vue'),
    },
    {
        path: '/dashboardsVendas/resumo-cliente',
        name: 'dashboard.resumo-cliente',
        meta: { title: 'Dashboard | Clientes' },
        component: () => import('@/views/dashboards/DashboardResumoCliente.vue'),
    },
    {
        path: '/dashboardsVendas/produtividade',
        name: 'dashboard.produtividade',
        meta: { title: 'Dashboard | Produtividade' },
        component: () => import('@/views/dashboards/DashboardProdutividade.vue'),
    },
]

export default dashboardsVendas
