import Vue from 'vue'

const recordsPerPage = 100

const QueryBuilder = {

    parser(search,) {

        const searchString = this.parseSearchString(search)
        console.log('SEARCH:')
        console.log(search)
        let query = ''
        if (searchString !== '') {
            query = searchString
        }
        return query
    },
    parseSearchString(search) {
        const currentSearch = []
        // eslint-disable-next-line no-restricted-syntax
        for (const el in search) {
            if (search[el] !== null || search[el] !== undefined  || search[el] !== []) {
                let value = search[el]

                if (search[el].hasOwnProperty('value') && !search[el].value) {
                    continue
                }

                if (search[el].value) {
                    value = search[el].value
                }
                let currentValue = this.getSearchValue(value)
                if (currentValue && currentValue.value) {
                    currentValue = currentValue.value
                }

                let operator = 'eq'
                if (search[el].operator) {
                    operator = search[el].operator
                }
                let fieldName = el
                if (search[el].field) {
                    fieldName = search[el].field
                }
                if (currentValue === '' || currentValue === null) {
                    continue
                }
                currentSearch.push(`${fieldName}:${operator}:${currentValue}`)
            }
        }

        return currentSearch.join(';')
    },

    getSearchValue(value) {
        let currentValue = value
        if (currentValue instanceof Array) {
            const partialValue = []
            for (const part of currentValue) {
                let partValue = part
                if (part && part?.value) {
                    partValue = part?.value
                }
                partialValue.push(partValue)
            }
            return partialValue.join('|')
        }
        if (currentValue && currentValue.value) {
            currentValue = currentValue.value
        }

        return currentValue
    },
}
Vue.prototype.$queryBuilder = QueryBuilder
window.queryBuilder = QueryBuilder
export default QueryBuilder
