const dashboardsFinanceiros = [
    {
        path: '/dashboard/exemplos/cartoes',
        name: 'ds.exemplos.cartoes',
        meta: { title: 'Dashboard | Cartões' },
        component: () => import('@/views/dashboards/exemplos/DashExemploCartoes.vue'),
    },
    {
        path: '/dashboard/exemplos/colunas',
        name: 'ds.exemplos.colunas',
        meta: { title: 'Dashboard | Colunas' },
        component: () => import('@/views/dashboards/exemplos/DashExemploColunas.vue'),
    },
    {
        path: '/dashboard/exemplos/tabelas',
        name: 'ds.exemplos.tabelas',
        meta: { title: 'Dashboard | Tabelas' },
        component: () => import('@/views/dashboards/exemplos/DashExemploTabelas.vue'),
    },
]

export default dashboardsFinanceiros
